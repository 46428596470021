<template>
    <div class="titleOld" v-if="old">
        <span @click="onClickLeft"><van-icon color="#D9D8E7" class="titleOldArrow" name="arrow"/></span>
        <br/>
        <span class="titleOldName">
            <span style="float: right">
                <slot name="right"></slot>
            </span>
            <strong>{{title}}</strong>
        </span>
    </div>
    <div class="newTitleTwo" v-else>
        <div style="float: right">
            <slot name="right"></slot>
        </div>
        <span @click="onClickLeft"><img src="../assets/imgs/icon/arrow-left.png" alt=""> {{title}}</span>
    </div>
</template>

<script>
    export default {
        props: {
            old: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
            url: {
                type: String,
                default: '/'
            }
        },
        methods: {
            onClickLeft() {
                if (this.url && this.url !== '/') {
                    this.$router.push(this.url);
                } else {
                    this.$router.back(-1);
                }
            },
        }
    }
</script>
